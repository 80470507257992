exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pre-earthquake-assessment-js": () => import("./../../../src/pages/pre_earthquake_assessment.js" /* webpackChunkName: "component---src-pages-pre-earthquake-assessment-js" */),
  "component---src-pages-project-single-js": () => import("./../../../src/pages/project-single.js" /* webpackChunkName: "component---src-pages-project-single-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tools-for-infrastructure-js": () => import("./../../../src/pages/tools_for_infrastructure.js" /* webpackChunkName: "component---src-pages-tools-for-infrastructure-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

